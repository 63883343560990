<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2H20C21.1046 2 22 2.89543 22 4V12M12 2H4C2.89543 2 2 2.89543 2 4V12M12 2V12M12 22H4C2.89543 22 2 21.1046 2 20V12M12 22H20C21.1046 22 22 21.1046 22 20V12M12 22V12M2 12H12M12 12H22" stroke="#E8E8F6" stroke-width="1.5"></path>
    <path d="M5 7H7M9 7H7M7 7V5M7 7V9" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M15 7H19" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M15 15.5H19" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M15 18.5H19" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round"></path>
    <path d="M5 15L7 17M9 19L7 17M7 17L9 15M7 17L5 19" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'CalcIco'
}
</script>
